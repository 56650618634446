import axios from "axios";

export default {
  post(url, request) {
    return axios
      .post(`${url}`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },
  get(url) {
    return axios
      .get(`${url}`)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },
  put(url, request) {
    return axios
      .put(`${url}`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },
  delete(url, request) {
    return axios
      .delete(`${url}`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  },
  postSkipAuth(url, request) {
    return axios
      .post(`${url}`, request)
      .then(response => Promise.resolve(response.data))
      .catch(error => Promise.reject(error));
  }
};
