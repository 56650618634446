export default {
  SET_COLLECTIONS: (state, payload) => {
    state.collections = payload; // eslint-disable-line
  },
  SET_SITE: (state, payload) => {
    state.site = payload; // eslint-disable-line
  },
  SET_COLLECTION: (state, payload) => {
    state.collection = payload; // eslint-disable-line
  },
  SET_ARTICLES: (state, payload) => {
    state.articles = payload; // eslint-disable-line
  },
  SET_ARTICLE: (state, payload) => {
    state.article = payload; // eslint-disable-line
  },
  SET_SEARCH_RESULTS: (state, payload) => {
    state.searchResults = payload; // eslint-disable-line
  }
};
