import Vue from "vue";
import Vuex from "vuex";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

Vue.use(Vuex);

// eslint-disable-next-line
export function createStore() {
  return new Vuex.Store({
    modules: {},
    state: {
      url: "",
      collections: [],
      site: {},
      collection: {},
      articles: [],
      article: {},
      searchResults: []
    },
    actions,
    getters,
    mutations,
    strict: true
  });
}
