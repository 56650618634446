<template>
  <div class="auth-wrapper">
    <div class="auth-form-wrapper">
      <div>
        <div
          v-if="showAlert"
          variant="danger"
          class="alert-wrapper"
          data-qa="error_msg"
        >
          <i class="icon icon-exclamation" />
          <span style="padding-left:4px">
            Please enter valid email id and password
          </span>

        </div>
      </div>
      <div class="form-card">
        <form @submit="login">
          <h3 class="form-title">
            Login to HelpNinja
          </h3>
          <div
            class="form-group"
            id="emailIdInputGroup"
          >
            <input
              ref="emailField"
              class="form-control"
              v-model="form.email"
              type="email"
              placeholder="Email"
              autocomplete="username"
              data-qa="login_email"
            />

          </div>

          <div
            class="form-group"
            id="passwordInputGroup"
          >

            <input
              ref="passwordField"
              v-model="form.password"
              class="form-control"
              type="password"
              placeholder="Password"
              autocomplete="current-password"
              data-qa="login_password"
            />
          </div>
          <div class="primary-btn-wrapper">
            <button
              :disabled="buttonLoaders.login"
              type="submit"
              class="hn-btn-primary"
              data-qa="login_button"
            >
              Login
            </button>
          </div>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

export default {
  data() {
    return {
      form: { email: "", password: "" },
      buttonLoaders: { login: false },
      showAlert: false
    };
  },

  methods: {
    login(event) {
      event.preventDefault();
      this.buttonLoaders.login = true;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.form.email, this.form.password)
        .then(currentUser => currentUser.user.getIdToken())
        .then(idToken =>
          this.$store.dispatch("createToken", {
            requestParams: { idToken }
          })
        )
        .then(response => {
          document.cookie = `fbtoken=${response.token}`;
          // A page redirect would suffice as the persistence is set to NONE.
          //   return firebase.auth().signOut();
          this.buttonLoaders.login = false;
          this.$router.push({ name: "home" });
        })

        .catch(err => {
          this.showAlert = true;
          this.buttonLoaders.login = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
