<template>
  <div>
    <the-topbar></the-topbar>
    <router-view></router-view>
  </div>

</template>

<script>
import TheTopbar from "./TheTopbar.vue";

export default {
  name: "TheHome",
  components: {
    TheTopbar
  }
};
</script>
<style>
</style>
