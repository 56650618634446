import Vue from "vue";
import VueViewer from "v-viewer";
import firebase from "firebase/app";
// import "firebase/auth";

import "viewerjs/dist/viewer.css";

// const firebaseConfig = {
//   apiKey: "AIzaSyDX4KKdvhpyblFu8dBl5Ma_MQAr1IgQJv8",
//   authDomain: "hndesk-local.firebaseapp.com",
//   databaseURL: "https://hndesk-local.firebaseio.com",
//   projectId: "hndesk-local",
//   storageBucket: "hndesk-local.appspot.com",
//   messagingSenderId: "979762899061"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyBZT_2dddEMTKKUAvtd_d02mHAntDzmOqI",
//   authDomain: "helpninja-staging.firebaseapp.com",
//   databaseURL: "https://helpninja-staging.firebaseio.com",
//   projectId: "helpninja-staging",
//   storageBucket: "helpninja-staging.appspot.com",
//   messagingSenderId: "212129025960"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCwQFiisPTBmoANi1G_kL587spCd-LUZIE",
  authDomain: "hndesk-prod.firebaseapp.com",
  databaseURL: "https://hndesk-prod.firebaseio.com",
  projectId: "hndesk-prod",
  storageBucket: "hndesk-prod.appspot.com",
  messagingSenderId: "543046235540"
};
firebase.initializeApp(firebaseConfig);

import { createApp } from "./app";

// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

Vue.use(VueViewer);

Vue.mixin({
  beforeMount() {
    const { asyncData } = this.$options;
    if (asyncData) {
      // assign the fetch operation to a promise
      // so that in components we can do `this.dataPromise.then(...)` to
      // perform other tasks after data is ready
      this.dataPromise = asyncData({
        store: this.$store,
        route: this.$route,
        url: "/public/"
      });
    }
  }
});

const { app, router, store } = createApp();

if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__);
}

router.onReady(() => {
  // Add router hook for handling asyncData.
  // Doing it after initial route is resolved so that we don't double-fetch
  // the data that we already have. Using `router.beforeResolve()` so that all
  // async components are resolved.
  // eslint-disable-next-line
  router.beforeResolve((to, from, next) => {
    const matched = router.getMatchedComponents(to);
    const prevMatched = router.getMatchedComponents(from);

    // we only care about non-previously-rendered components,
    // so we compare them until the two matched lists differ
    let diffed = false;
    const activated = matched.filter((c, i) => {
      // eslint-disable-line
      return diffed || (diffed = prevMatched[i] !== c);
    });

    if (!activated.length) {
      return next();
    }

    // this is where we should trigger a loading indicator if there is one

    Promise.all(
      activated.map(c => {
        // eslint-disable-line
        if (c.asyncData) {
          return c.asyncData({
            store,
            route: to,
            url: "/public/"
          });
        }
      })
    )
      .then(() => {
        // stop loading indicator

        next();
      })
      .catch(next);
  });

  app.$mount("#app");
});
