<template>
  <main class="hn-article-page">
    <section class="full-page-cont">
      <div class="space-md"></div>

      <section class="page-guide-container">
        <a
          class="hn-page-guide-text go-before"
          @click="gotoHome"
        >All Collections</a>
        <i class="icon icon-long-arrow-right"></i>
        <span
          class="hn-page-guide-text go-before"
          @click="gotoCollection(article.collectionId)"
          v-if="article.collectionId"
        >{{article.collectionId.name}}</span>
        <i class="icon icon-long-arrow-right"></i>
        <span class="hn-page-guide-text hn-current-page">{{article.name}}</span>
      </section>
      <section class="hn-article-content-contaner">
        <article
          v-viewer="{toolbar:false}"
          id="fullArticle"
          class="hn-article-content "
          v-html="article.text"
        ></article>
        <footer>
          <div
            class="hn-help-content"
            v-if="site.showContactForm"
            @click="showModal=true"
          > <i class="icon icon-mail-envelope"></i> Still need help? <a>Contact Us</a></div>
          <section class="hn-aricle-feedback-section">
            <div class="hn-aricle-feedback-text">Did this answer your question?</div>

            <section class="hn-feedback-emoji-container">
              <img
                class="hn-feedback-emoji"
                :class="{'active':currentRating ==='positiveRating' , 'disabled':isAlreadyRated && currentRating !='positiveRating' }"
                :src="happySmiley"
                @click="setRating('positiveRating')"
              >
              <img
                class="hn-feedback-emoji"
                :src="neutralSmiley"
                :class="{'active':currentRating ==='neutralRating','disabled':isAlreadyRated && currentRating !='neutralRating'}"
                @click="setRating('neutralRating')"
              >
              <img
                class="hn-feedback-emoji"
                :src="sadSmiley"
                :class="{'active':currentRating ==='negativeRating', 'disabled':isAlreadyRated && currentRating !='negativeRating'}"
                @click="setRating('negativeRating')"
              >
            </section>
          </section>
        </footer>
      </section>

    </section>

    <contact-modal
      v-if="showModal"
      @close="showModal=false"
    ></contact-modal>
  </main>
</template>

<script>
import sadSmiley from "../assets/emoji-sad.png";
import neutralSmiley from "../assets/emoji-okay.png";
import happySmiley from "../assets/emoji-happy.png";

import ContactModal from "./ContactModal.vue";

export default {
  name: "TheArticle",
  components: {
    ContactModal
  },
  data() {
    return {
      isAlreadyRated: false,
      currentRating: "",
      happySmiley,
      sadSmiley,
      neutralSmiley,
      showModal: false
    };
  },
  asyncData({ store, route, url }) {
    // return the Promise from the action
    return store.dispatch("getArticle", { slug: route.params.slug, url });
  },
  computed: {
    article() {
      return this.$store.getters.getArticle;
    },
    site() {
      return this.$store.state.site;
    }
  },
  metaInfo() {
    return {
      title: `${this.site.name} - ${this.article.name}`
    };
  },
  mounted() {
    this.$store.dispatch("updateVisit", {
      articleId: this.article.id
    });
  },
  methods: {
    gotoHome() {
      this.$router.push({ name: "home" });
    },
    gotoCollection(collection) {
      this.$router.push({
        name: "collection",
        params: { slug: collection.slug }
      });
    },
    setRating(rating) {
      if (!this.isAlreadyRated) {
        this.currentRating = rating;
        this.$store.dispatch("setRating", {
          articleId: this.article.id,
          requestParams: { rating }
        });
        this.isAlreadyRated = true;
      }
    }
  }
};
</script>

<style>
</style>
