import api from "./utils/api";

const actions = {
  getCollections({ commit }, payload) {
    return api.get(`${payload.url}collections`).then(response => {
      commit("SET_COLLECTIONS", response.collections);
      commit("SET_SITE", response.site);
    });
  },
  getCollection({ commit }, payload) {
    return api
      .get(`${payload.url}collection/${payload.slug}`)
      .then(response => {
        commit("SET_COLLECTION", response.collection);
        commit("SET_ARTICLES", response.articles);
        commit("SET_SITE", response.site);
      })
      .catch(err => {
        console.log(err);
      });
  },
  getArticle({ commit }, payload) {
    return api
      .get(`${payload.url}article/${payload.slug}`)
      .then(response => {
        commit("SET_ARTICLE", response.article);
        commit("SET_SITE", response.site);
      })
      .catch(err => {
        console.log(err);
      });
  },
  searchArticles({ commit }, payload) {
    return api
      .get(`/public/search?q=${payload.searchQuery}`)
      .then(response => {
        commit("SET_SITE", response.site);
        commit("SET_SEARCH_RESULTS", response.conversations);
      })
      .catch(err => {
        console.log(err);
      });
  },
  setRating({ commit }, payload) {
    return api
      .post(`/public/rating/${payload.articleId}`, payload.requestParams)
      .catch(err => {
        console.log(err);
      });
  },
  updateVisit({ commit }, payload) {
    return api.post(`/public/visit/${payload.articleId}`).catch(err => {
      console.log(err);
    });
  },
  sendMail({ commit }, payload) {
    return api.post(`/public/contact`, payload.requestParams).catch(err => {
      console.log(err);
    });
  },
  createToken({ commit }, payload) {
    return api
      .post(`/api/v0/user/createToken`, payload.requestParams)
      .catch(err => {
        console.log(err);
      });
  }
};

export default actions;
