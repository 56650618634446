
<template>
  <header id="hn-help-doc-page-wrapper">
    <v-style v-if="site.colors">
      :root {
      --topbar-color: {{site.bgColor}};
      --articletext-color: {{site.colors.articleTextColor}};
      --link-color: {{site.colors.linkColor}};
      --collection-titile-color:{{site.colors.collectionTitleColor}};
      }
    </v-style>

    <section class="hn-help-doc-head-section">
      <section class="doc-head-section-container">
        <section class="doc-brand-name">
          <div class="doc-brand-contain">
            <div
              v-if="site.logoUrl && site.logoUrl.length>0"
              class="doc-brand-logo hn-cursor"
              style="display: flex;"
              @click="gotoHome"
            >
              <img :src="site.logoUrl">
            </div>
            <div
              v-if="site.logoUrl && site.logoUrl.length>0"
              class="hn-vertical-line"
            ></div>
            <div class="doc-page-name">{{site.pageName}}</div>
          </div>
          <i
            class="icon icon-external-icon"
            v-if="site.homePageURL"
            @click="openSite"
          ></i>
        </section>
        <h1
          class="doc-page-title hn-cursor"
          @click="gotoHome"
        >{{site.headerText}}</h1>
        <i
          class="icon icon-search-icon"
          :class="{'active':searchActive}"
        ></i>
        <form @submit="gotoSearchRoute">
          <input
            type="text"
            placeholder="Search for answers…"
            class="doc-article-search-input-box"
            v-model="searchText"
            :class="{'set-white-input':searchText.length>0}"
            @focus="searchActive=true"
            @blur="searchActive=false"
            @keydown="listenKeypress"
          >
        </form>

      </section>
    </section>
    <a
      v-if="site.showHNBranding"
      class="hn-powered-by-hn"
      :href="'https://helpninja.com?utm_source=doc_inapp_link&ref='+site.subDomain"
      target="_blank"
    >
      <div class="hn-power-text">We're</div>
      <div><img
          src="../assets/hn-power.svg"
          class="hn-power-sign"
        ></div>
      <div class="hn-power-text">by</div>
      <div><img
          src="../assets/helpninja-logo-blue.svg"
          class="hn-logo-blue"
        ></div>
    </a>
  </header>
</template>

<script>
import Vue from "vue";

Vue.component("v-style", {
  // eslint-disable-next-line
  render: function(createElement) {
    return createElement("style", this.$slots.default);
  }
});
export default {
  name: "TheTopbar",
  data() {
    return {
      searchText: "",
      searchActive: false
    };
  },
  computed: {
    site() {
      return this.$store.state.site;
    },
    scripts() {
      const scripts = [];
      const site = this.site;
      if (site.externalJSScripts) {
        site.externalJSScripts.forEach(externalLink => {
          scripts.push({
            src: externalLink,
            async: true,
            defer: true
          });
        });
      }
      return scripts;
    },
    metaLinks() {
      const site = this.site;
      const links = [
        {
          rel: "icon",
          href: this.site.favIconUrl
        }
      ];
      if (site.externalCssLinks) {
        site.externalCssLinks.forEach(externalLink => {
          links.push({
            rel: "stylesheet",
            href: externalLink
          });
        });
      }

      return links;
    }
  },
  metaInfo() {
    return {
      script: this.scripts,
      link: this.metaLinks,
      meta: [
        { name: "robots", content: this.site.visiblity ? "all" : "none" },
        { name: "description", content: this.site.metaDescription }
      ]
    };
  },
  created() {},
  methods: {
    gotoSearchRoute(event) {
      event.preventDefault();
      if (this.searchText === "") {
        this.gotoHome();
        return;
      }
      this.$router.push({ name: "Search", query: { q: this.searchText } });
    },
    gotoHome() {
      this.$router.push({ name: "home" });
    },
    listenKeypress(event) {
      if (event.keyCode === 27) {
        this.searchText = "";
        this.gotoHome();
      }
    },
    openSite() {
      window.open(this.site.homePageURL, "_blank");
    }
  }
};
</script>

<style>
</style>
