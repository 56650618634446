<template>
  <main class="hn-collection-page">
    <section class="full-page-cont">
      <div class="space-md"></div>
      <section class="page-guide-container">
        <a
          class="hn-page-guide-text go-before"
          @click="gotoHome"
        >All Collections</a>
        <i class="icon icon-long-arrow-right"></i>
        <span class="hn-page-guide-text hn-current-page">{{collection.name}}</span>
      </section>
      <section class="hn-collections-list-container">
        <section class="hn-collections-details-container">
          <!-- <i class="icon icon-book-icon"></i> -->
          <section class="collection-title">
            <section class="collection-name-wrapper">
              <h1 class="collection-name">{{collection.name}}</h1>
            </section>

            <section>
              <h2 class="collection-desc opacity-64">{{collection.description}}</h2>
            </section>

            <section class="article-label">{{collection.articles?collection.articles.length:0}} Articles</section>
          </section>
        </section>

        <router-link
          v-for="(article,index) in collection.articles"
          :key="index"
          :to="{name:'article',params:{slug:article.slug}}"
          :class="article.slug"
        >
          <article class="hn-collections-cards-style">
            <span class="icon icon-collection-lg icon-lg opacity-87"></span>
            <section class="collection-title">
              <h1 class="article-name">{{article.name}}</h1>

              <h2 class="collection-desc opacity-64">{{article.description}}</h2>
            </section>
          </article>
        </router-link>

      </section>
    </section>
  </main>
</template>

<script>
export default {
  name: "TheCollection",
  asyncData({ store, route, url }) {
    // return the Promise from the action
    return store.dispatch("getCollection", { slug: route.params.slug, url });
  },
  computed: {
    collection() {
      return this.$store.state.collection;
    },
    site() {
      return this.$store.state.site;
    }
  },
  metaInfo() {
    return {
      title: `${this.site.name} - ${this.collection.name}`
    };
  },
  methods: {
    gotoHome() {
      this.$router.push({ name: "home" });
    },
    gotoArticle(article) {
      this.$router.push({ name: "article", params: { slug: article.slug } });
    }
  }
};
</script>

<style>
</style>
