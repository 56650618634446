<template>
  <main class="hn-doc-home-page">
    <section class="full-page-cont">
      <div class="space-md"></div>
      <section>
        <router-link
          class="hn-collections-cards-style"
          :to="{name:'collection',params:{slug:collection.slug}}"
          v-for="(collection,index) in collections"
          :key="index"
          :class="collection.slug"
        >

          <section class="hn-collections-info-container">
            <!-- <span class="icon icon-book-icon"></span> -->
            <section class="collection-title">
              <h1 class="collection-name">{{collection.name}}</h1>

              <h2
                v-if="collection.description && collection.description.length>0"
                class="collection-desc opacity-64"
              >{{collection.description}}</h2>
            </section>
          </section>
          <section class="article-count-label-wraper">
            <span class="article-label-arrow article-count"></span>
            <span class="article-label article-count">{{collection.articles.length}} <span class="article-count-name">Articles</span></span>
          </section>
        </router-link>
      </section>
    </section>
  </main>
</template>

<script>
export default {
  name: "TheCollections",
  asyncData({ store, url }) {
    // return the Promise from the action
    return store.dispatch("getCollections", { url });
  },
  computed: {
    collections() {
      return this.$store.state.collections;
    },
    site() {
      return this.$store.state.site;
    }
  },
  metaInfo() {
    return {
      title: `${this.site.name} - ${this.site.pageName}`
    };
  },
  methods: {
    gotoArticle() {
      this.$router.push({ name: "article" });
    },
    gotoCollection(collection) {
      this.$router.push({
        name: "collection",
        params: { slug: collection.slug }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
