import Vue from "vue";
import { sync } from "vuex-router-sync";
import App from "./App.vue";
import { createRouter } from "./router";
import { createStore } from "./store";

import "./sass/main.scss";

// eslint-disable-next-line
export function createApp() {
  // create router and store instances
  const router = createRouter();
  const store = createStore();

  // sync so that route state is available as part of the store
  sync(store, router);

  // create the app instance, injecting both the router and the store
  const app = new Vue({
    router,
    store,
    render: h => h(App)
  });

  // expose the app, the router and the store.
  return { app, router, store };
}
