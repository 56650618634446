<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">Contact Us</slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              <form>
                <div class="form-group">
                  <label>Email</label>
                  <input class="form-control" v-model="form.email" type="email">
                </div>
                <div class="space-sm"> </div>
                <div class="form-group">
                  <label>Message</label>
                  <textarea rows="5" class="form-control" v-model="form.message"></textarea>
                </div>
                <div>
                  <button class="modal-default-button" @click="sendEmail" type="button">Send</button>
                  <button class="modal-default-button secondary" @click="close" type="button">Close</button>
                </div>
              </form>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ContactModal",
  data() {
    return {
      form: {
        email: "",
        message: ""
      }
    };
  },
  methods: {
    async sendEmail() {
      const slug = this.$route.params.slug;
      await this.$store.dispatch("sendMail", {
        requestParams: { ...this.form, slug }
      });
      this.close();
    },
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style>
</style>
