// router.js
import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";
import TheHome from "./components/TheHome.vue";
import TheCollection from "./components/TheCollection.vue";
import TheArticle from "./components/TheArticle.vue";
import TheSearch from "./components/TheSearch.vue";
import TheLogin from "./components/TheLogin.vue";
import TheCollections from "./components/TheCollections.vue";

Vue.use(Router);
Vue.use(Meta, {
  keyName: "metaInfo", // the component option name that vue-meta looks for meta info on.
  attribute: "data-vue-meta", // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: "data-vue-meta-server-rendered", // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: "vmid" // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

// eslint-disable-next-line
export function createRouter() {
  return new Router({
    mode: "history",
    fallback: false,
    scrollBehavior: () => ({ y: 0 }),
    routes: [
      {
        path: "/",
        name: "home",
        component: TheHome,
        redirect: { name: "TheCollections" },
        children: [
          {
            path: "/",
            name: "TheCollections",
            component: TheCollections
          },
          {
            path: "/collection/:slug",
            name: "collection",
            component: TheCollection
          },
          {
            path: "/article/:slug",
            name: "article",
            component: TheArticle
          },
          {
            path: "/search",
            name: "Search",
            component: TheSearch
          }
        ]
      },
      {
        path: "/login",
        name: "login",
        component: TheLogin
      }
    ]
  });
}
