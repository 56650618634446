<template>
  <main class="hn-doc-home-page hn-collection-page">
    <section class="full-page-cont">
      <div class="space-md"></div>
      <section class="hn-searched-content">
        <span
          v-if="searchResults && searchResults.length>0"
          class="hn-searched-content-lable"
        >Search results for: {{searchQuery}}</span>
        <span
          v-if="!isSearching && searchResults && searchResults.length===0"
          class="hn-searched-content-lable"
        >We couldn't find any articles for:{{searchQuery}}</span>
      </section>
      <section>
        <section
          v-for="(article,index) in searchResults"
          :key="index"
          @click="gotoArticle(article)"
          class="hn-collections-list-container"
        >
          <section class="hn-collections-cards-style">
            <i class="icon icon-collection-lg icon-lg opacity-87"></i>
            <article class="collection-title">
              <h1 class="article-name">{{article.name}}</h1>
              <h2 class="collection-desc opacity-64">{{article.description}}</h2>
            </article>
          </section>
        </section>
      </section>

    </section>
  </main>
</template>

<script>
export default {
  name: "TheSearch",
  data() {
    return {
      searchQuery: "",
      isSearching: false
    };
  },
  computed: {
    searchResults() {
      return this.$store.state.searchResults;
    }
  },
  watch: {
    "$route.query.q": {
      handler(value) {
        this.searchQuery = value;
        this.searchArticles(value);
      }
    }
  },
  mounted() {
    const query = this.$route.query.q;
    this.searchQuery = query;
    this.searchArticles(query);
  },
  methods: {
    gotoArticle(article) {
      this.$router.push({ name: "article", params: { slug: article.slug } });
    },
    searchArticles(searchQuery) {
      this.isSearching = true;

      this.$store
        .dispatch("searchArticles", { searchQuery })
        .then(() => {
          this.isSearching = false;
        })
        .catch(() => {
          this.isSearching = false;
        });
    }
  }
};
</script>
<style>
</style>
