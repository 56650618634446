import { render, staticRenderFns } from "./TheHome.vue?vue&type=template&id=4562b231&"
import script from "./TheHome.vue?vue&type=script&lang=js&"
export * from "./TheHome.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./TheHome.vue?vue&type=style&index=0&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "12abbe5d"
  
)

component.options.__file = "TheHome.vue"
export default component.exports